import idEndpointGetter from '@/api/idEndpointGetter'
import useBasicApi from '@/api/useBasicApi'
import config from '@/config'

const axios = config.urls.AXIOS_API

export default function projectUsersApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/users`
  return idEndpointGetter({
    ENDPOINT,
    ...useBasicApi(ENDPOINT, ['get', 'getNext', 'remove']),
    add: ({ userId, role = undefined }) =>
      axios.post(`${ENDPOINT}/`, {
        user_id: userId,
        role,
      }),
  })
}
