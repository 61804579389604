import display from './modules/display.js'
import icons from './modules/icons.js'
import urls from './modules/urls.js'
import { ref } from 'vue'
import axios from 'axios'
import { version, branch } from '@/../package.json'

const VERSIONS = ref({
  backend: {},
  frontend: { version, branch },
})
axios.get(`${urls.URL_API}/version/`).then(({ data }) => {
  VERSIONS.value.backend = data
})

export default Object.freeze({
  urls,
  icons,
  display,
  VERSIONS,
})
