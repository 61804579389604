import useBasicApi from '@/api/useBasicApi'
import config from '@/config'
import idEndpointGetter from '@/api/idEndpointGetter'

const axios = config.urls.AXIOS_API

export default function HWSCapabilitiesApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/capabilities`
  return idEndpointGetter({
    ENDPOINT,
    ...useBasicApi(ENDPOINT, ['get']),
    for: capabilityId => {
      const HWS_CAPABILITY_ENDPOINT = `${ENDPOINT}/${capabilityId}`
      return {
        ENDPOINT: HWS_CAPABILITY_ENDPOINT,
        ...useBasicApi(HWS_CAPABILITY_ENDPOINT, ['remove']),
        add: async () => axios.post(`${HWS_CAPABILITY_ENDPOINT}/`),
      }
    },
  })
}
