import { ref, watchEffect } from 'vue'

/*
 * To make observer be enabled, pass root to observed component ref
 * isEnabled is optional boolean
 */
export default function useObserver(
  constructor,
  onObserved = () => {},
  { isEnabled = undefined, options = {}, root = undefined } = {}
) {
  const observer = ref(null)
  root ??= ref(null)

  const { observeConverter = v => v } = options ?? {}

  watchEffect(() => {
    if (isEnabled !== undefined && !isEnabled?.value) return
    if (!root.value) return
    if (observer.value) delete observer.value
    observer.value = new constructor(onObserved, { root: null, threshold: 0.5 })
    observer.value.observe(observeConverter(root.value))
  })

  const stopper = () => {
    observer.value.unobserve(root.value)
  }

  return {
    root,
    observer,
    stopper,
  }
}
