import useBasicApi from '@/api/useBasicApi'
import { ServerEvent } from '@/enums'

export default function stepsApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/steps`

  return {
    ENDPOINT,
    CREATE_EVENT: ServerEvent.TASK_STEP_CREATED,
    DELETE_EVENT: ServerEvent.TASK_STEP_DELETED,
    UPDATE_EVENT: ServerEvent.TASK_STEP_UPDATED,
    ...useBasicApi(ENDPOINT, ['get', 'getNext']),
    for: taskStepId => {
      const TASK_STEP_ENDPOINT = `${ENDPOINT}/${taskStepId}`
      return {
        ENDPOINT: TASK_STEP_ENDPOINT,
        ...useBasicApi(TASK_STEP_ENDPOINT, ['get', 'remove']),
      }
    },
  }
}
