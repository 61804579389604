import { computed } from 'vue'

export default function useRouterComponent({
  to,
  disabled,
  loading,
  routerLink,
  linkComponent = 'router-link',
}) {
  const component = computed(() => {
    if (disabled?.value || loading?.value || !to?.value) return 'div'
    return to?.value instanceof Object || routerLink?.value ? linkComponent : 'a'
  })
  const prop = computed(() => (component.value === linkComponent ? 'to' : 'href'))

  return {
    component,
    prop,
  }
}
