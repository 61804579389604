import usersApi from '@/api/modules/users'
import eventsApi from '@/api/modules/events/index'
import projectsApi from '@/api/modules/projects/index'
import settingsApi from '@/api/modules/system/settings'
import authConfigApi from '@/api/modules/auth/config'

export default {
  auth: {
    config: authConfigApi('/auth'),
  },
  projects: projectsApi(),
  system: {
    settings: settingsApi('/system'),
  },
  users: usersApi(),
  events: eventsApi(),
}
