import idEndpointGetter from '@/api/idEndpointGetter'
import statusApi from '@/api/modules/projects/tasks/status'
import stepsApi from '@/api/modules/projects/tasks/steps'
import useBasicApi from '@/api/useBasicApi'
import config from '@/config'
import { ServerEvent, TaskActiveStatuses } from '@/enums'
import taskPackagesApi from '@/api/modules/projects/tasks/packages'
import tasksCompatibleBookings from '@/api/modules/projects/tasks/bookings'

const axios = config.urls.AXIOS_API

export default function tasksApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/tasks`

  const create = ({
    name,
    tests,
    tests_type,
    libs_version,
    tests_version,
    software_version,
    timeout,
  }) =>
    axios.post(`${ENDPOINT}/`, {
      name,
      tests,
      timeout,
      tests_type,
      libs_version,
      tests_version,
      software_version,
    })

  const isAbortAvailable = item => TaskActiveStatuses.includes(item?.status)

  const AVAILABLE_HWS_ENDPOINT = `${ENDPOINT}/available_hardware_setup/`

  return idEndpointGetter({
    ENDPOINT,
    CREATE_EVENT: ServerEvent.TASK_CREATED,
    DELETE_EVENT: ServerEvent.TASK_DELETED,
    UPDATE_EVENT: ServerEvent.TASK_UPDATED,
    ...useBasicApi(ENDPOINT, ['get', 'getNext']),
    create,
    isAbortAvailable,
    availableHardwareSetup: {
      ENDPOINT: AVAILABLE_HWS_ENDPOINT,
      find: async ({ tests, tests_type }, options) =>
        axios.post(AVAILABLE_HWS_ENDPOINT, { tests, tests_type }, options),
    },
    for: taskId => {
      const TASK_ENDPOINT = `${ENDPOINT}/${taskId}`

      const abort = async () => axios.post(`${TASK_ENDPOINT}/abort/`)

      return {
        ENDPOINT: TASK_ENDPOINT,
        update: async ({ name, tests, tests_type, software_version, timeout, bookingId }) =>
          axios.patch(`${TASK_ENDPOINT}/`, {
            name,
            tests,
            tests_type,
            software_version,
            timeout,
            booking_id: bookingId,
          }),
        ...useBasicApi(TASK_ENDPOINT, ['get', 'remove']),
        ...idEndpointGetter({
          for: packageType => {
            const PACKAGE_ENDPOINT = `${TASK_ENDPOINT}/${packageType}`
            return {
              ENDPOINT: PACKAGE_ENDPOINT,
              ...taskPackagesApi(PACKAGE_ENDPOINT),
            }
          },
        }),
        steps: stepsApi(TASK_ENDPOINT),
        status: statusApi(TASK_ENDPOINT),
        bookings: tasksCompatibleBookings(TASK_ENDPOINT),
        abort,
        abortItem: async item =>
          new Promise(resolve => {
            item.loadingAbort = true
            abort()
              .then(({ data }) => resolve(data))
              .finally(() => {
                item.loadingAbort = false
              })
          }),
        run: async () => axios.post(`${TASK_ENDPOINT}/run/`),
      }
    },
  })
}
