import bookingsApi from './bookings'
import capabilitiesApi from './capabilities'
import configsApi from './configs'
import nodesApi from './nodes'
import tasksApi from './tasks'
import projectSettingsApi from '@/api/modules/projects/settings'
import idEndpointGetter from '@/api/idEndpointGetter'
import useBasicApi from '@/api/useBasicApi'
import hardwareSetupsApi from '@/api/modules/projects/hardwareSetups/index'
import projectUsersApi from '@/api/modules/projects/users'

export default function projectsApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/projects`

  return idEndpointGetter({
    ENDPOINT,
    ...useBasicApi(ENDPOINT, ['get', 'getNext']),
    for: projectId => {
      const PROJECT_ENDPOINT = `${ENDPOINT}/${projectId}`
      return {
        ENDPOINT: PROJECT_ENDPOINT,
        ...useBasicApi(PROJECT_ENDPOINT, ['get', 'remove']),
        hardwareSetups: hardwareSetupsApi(PROJECT_ENDPOINT),
        bookings: bookingsApi(PROJECT_ENDPOINT),
        tasks: tasksApi(PROJECT_ENDPOINT),
        configs: configsApi(PROJECT_ENDPOINT),
        nodes: nodesApi(PROJECT_ENDPOINT),
        settings: projectSettingsApi(PROJECT_ENDPOINT),
        capabilities: capabilitiesApi(PROJECT_ENDPOINT),
        users: projectUsersApi(PROJECT_ENDPOINT),
      }
    },
  })
}
