import useBasicApi from '@/api/useBasicApi'
import idEndpointGetter from '@/api/idEndpointGetter'

export default function usersApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/users`
  return idEndpointGetter({
    ENDPOINT,
    ...useBasicApi(ENDPOINT, ['get', 'getNext']),
    for: userId => {
      const USER_ENDPOINT = `${ENDPOINT}/${userId}`
      return {
        ENDPOINT: USER_ENDPOINT,
        ...useBasicApi(USER_ENDPOINT, ['get']),
      }
    },
  })
}
