import config from '@/config'
import useBasicApi from '@/api/useBasicApi'

const axios = config.urls.AXIOS_API

export default function settingsApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/settings`
  const ENDPOINT_VALUES = `${ENDPOINT}/values`

  return {
    ...useBasicApi(ENDPOINT, ['get']),
    values: {
      ...useBasicApi(ENDPOINT_VALUES, ['get']),
      update: async ({ values }) => axios.patch(`${ENDPOINT_VALUES}/`, { values }),
    },
  }
}
