import { ref } from 'vue'

/**
 * Use visibility toggler. Provides refs and functions to update visibility state
 *
 * @param {Object} arg1 The argument 1
 * @param {import('vue').Ref<boolean>} arg1.isVisibleRef Indicates if visible reference
 * @param {import('vue').Ref<boolean>} arg1.isDisabledRef Indicates if disabled reference
 */
export const useVisibilityToggler = ({
  isVisibleRef = undefined,
  isDisabledRef = undefined,
} = {}) => {
  const isVisible = isVisibleRef ?? ref(false)
  const isDisabled = isDisabledRef ?? ref(false)
  const open = () => {
    if (isDisabled.value) return
    isVisible.value = true
  }
  const close = () => (isVisible.value = false)
  const toggle = () => {
    if (isDisabled.value) return
    isVisible.value = !isVisible.value
  }

  return {
    isVisible,
    isDisabled,
    open,
    close,
    toggle,
  }
}
