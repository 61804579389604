import { ref, watch } from 'vue'

export default function useDebounce(value, timeout = 300, callback = undefined, watchOptions = {}) {
  const lastUpdated = ref()
  const debounced = ref()
  watch(
    value,
    v => {
      lastUpdated.value = Date.now()
      setTimeout(() => {
        if (Date.now() - lastUpdated.value <= timeout) return
        debounced.value = v
        callback?.()
      }, timeout + 10)
    },
    watchOptions
  )

  return {
    lastUpdated,
    debounced,
  }
}
