import useBasicApi from '@/api/useBasicApi'
import idEndpointGetter from '@/api/idEndpointGetter'

export default function hooksApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/hooks`
  return idEndpointGetter({
    ENDPOINT,
    ...useBasicApi(ENDPOINT, ['get', 'getNext']),
    for: eventHookId => {
      const EVENT_HOOK_ENDPOINT = `${ENDPOINT}/${eventHookId}`
      return {
        ENDPOINT: EVENT_HOOK_ENDPOINT,
        ...useBasicApi(EVENT_HOOK_ENDPOINT, ['get']),
      }
    },
  })
}
