import { ref } from 'vue'

export default function useDebounceFunction(callback, timeout = 300) {
  const lastUpdated = ref()
  // do not replace function declaration to
  // () => {} style because of arguments object
  function f() {
    const args = arguments
    lastUpdated.value = Date.now()
    setTimeout(() => {
      if (Date.now() - lastUpdated.value > timeout) {
        callback?.apply?.(null, args)
      }
    }, timeout + 10)
  }
  return f
}
