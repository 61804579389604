import useBasicApi from '@/api/useBasicApi'
import idEndpointGetter from '@/api/idEndpointGetter'

export default function devicesApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/devices`

  return idEndpointGetter({
    ENDPOINT,
    ...useBasicApi(ENDPOINT, ['get', 'getNext']),

    for: deviceId => {
      const DEVICE_ENDPOINT = `${ENDPOINT}/${deviceId}`
      return {
        ENDPOINT: DEVICE_ENDPOINT,
        ...useBasicApi(DEVICE_ENDPOINT, ['get']),
      }
    },
  })
}
