<template>
  <div
    id="page-content"
    class="min-w-screen relative flex min-h-screen w-full grow flex-col"
  >
    <div
      id="absolute-target"
      class="relative z-50"
    />
    <NotificationsView />
    <ConfirmationDialogs />
    <RouterView />
    <CookiesBanner class="fixed bottom-4 right-8 z-10" />
  </div>
</template>

<script setup>
import { whenever } from '@vueuse/core'
import { computed, defineAsyncComponent, ref } from 'vue'
import CookiesBanner from '@/components/CookiesBanner'
import NotificationsView from '@/components/NotificationsView'
import { user } from '@/composables/useAuth'
import { notifyWarning } from '@/composables/useNotifications'
import { projectKey } from '@/composables/useProject'
import { connect, convert, on } from '@/composables/useServerEvents'
import api from '@/api'
import { ComparisonFilters } from '@/enums'
import { getDuration } from '@/utils/time'

const ConfirmationDialogs = defineAsyncComponent(() =>
  import('@/components/Layout/ConfirmationDialogs')
)
connect()
const apiProjects = computed(() => api.projects.for(projectKey.value))
const apiBookings = computed(() => apiProjects.value.bookings)
const apiTD = computed(() => apiProjects.value.hardwareSetups)

const setBookingTimer = async booking => {
  clearTimeout(booking?.timer)
  const FIVE_MIN_IN_MS = 5 * 60 * 1000
  const timeLeftMs = new Date(booking.end_date) - new Date()
  const tdName = (await apiTD.value[booking.hardware_setup_id].get()).data.name
  const warnMsg = `Booking of ${tdName} hardware setup will end in `
  if (timeLeftMs < FIVE_MIN_IN_MS) notifyWarning(`${warnMsg}${getDuration(timeLeftMs)}`)
  else {
    booking.timer = setTimeout(
      () => notifyWarning(`${warnMsg}5 minutes`),
      timeLeftMs - FIVE_MIN_IN_MS
    )
  }
  return booking
}

const activeBookingsItems = ref([])

on(
  apiBookings.value.UPDATE_EVENT,
  item => {
    item = convert(item)
    const updatedBooking = activeBookingsItems.value?.findBy({ id: item?.id })
    if (updatedBooking) {
      updatedBooking?.merge(item)
      setBookingTimer(updatedBooking)
    }
  },
  false
)

on(
  apiBookings.value.CREATE_EVENT,
  async item => {
    item = convert(item)
    if (item.user_id === user.value.id) activeBookingsItems.value.push(await setBookingTimer(item))
  },
  false
)

on(
  apiBookings.value.DELETE_EVENT,
  item => {
    const activeBooking = activeBookingsItems.value?.findBy({ id: convert(item)?.id })
    clearTimeout(activeBooking?.timer)
    activeBookingsItems.value = activeBookingsItems.value?.remove(activeBooking)
  },
  false
)

whenever(
  apiBookings,
  async nv => {
    activeBookingsItems.value.forEach(it => clearTimeout(it?.timer))
    const { data } = await nv.get({
      filter: {
        limit: Number.MAX_SAFE_INTEGER,
        user_id: user.value.id,
        end_date: `${ComparisonFilters.GE}:${new Date().toJSON()}`,
      },
    })
    activeBookingsItems.value = data.results.applyFunc(it => it.forEach(setBookingTimer))
  },
  { immediate: true }
)
</script>
