import config from '@/config'

const axios = config.urls.AXIOS_API

export default function maintenanceApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/maintenance/`

  return {
    ENDPOINT,
    on: () => axios.post(ENDPOINT),
    off: () => axios.delete(ENDPOINT),
  }
}
