import useBasicApi from '@/api/useBasicApi'
import config from '@/config'
import { ServerEvent } from '@/enums'
import idEndpointGetter from '@/api/idEndpointGetter'

const axios = config.urls.AXIOS_API

export default function instancesApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/capability_instances`

  return idEndpointGetter({
    ENDPOINT,
    CREATE_EVENT: ServerEvent.CAPABILITY_INSTANCE_CREATED,
    DELETE_EVENT: ServerEvent.CAPABILITY_INSTANCE_DELETED,
    UPDATE_EVENT: ServerEvent.CAPABILITY_INSTANCE_UPDATED,
    create: async ({ value }) => axios.post(`${ENDPOINT}/`, { value }),
    ...useBasicApi(ENDPOINT, ['get', 'getNext']),
    for: instanceId => {
      const INSTANCE_ENDPOINT = `${ENDPOINT}/${instanceId}`
      const instancesApi = useBasicApi(INSTANCE_ENDPOINT, ['get', 'remove'])
      return {
        ENDPOINT: INSTANCE_ENDPOINT,
        update: async value => axios.put(`${INSTANCE_ENDPOINT}/`, { value }),
        deleteItem: async item => {
          item.loadingDelete = true
          const { confirmAction } = await import('@/composables/useConfirmationDialogs')
          const confirmed = await confirmAction({
            text: `Do you want to delete capability value ${item.value || ''}?`,
          })
          if (!confirmed) {
            item.loadingDelete = false
            return
          }
          try {
            await instancesApi.remove()
          } finally {
            item.loadingDelete = false
          }
        },
        ...instancesApi,
      }
    },
  })
}
