import instancesApi from './instances'
import idEndpointGetter from '@/api/idEndpointGetter'
import useBasicApi from '@/api/useBasicApi'
import config from '@/config'
import { ServerEvent } from '@/enums'

const axios = config.urls.AXIOS_API

export default function api(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/capabilities`

  return idEndpointGetter({
    ENDPOINT,
    CREATE_EVENT: ServerEvent.CAPABILITY_CREATED,
    DELETE_EVENT: ServerEvent.CAPABILITY_DELETED,
    UPDATE_EVENT: ServerEvent.CAPABILITY_UPDATED,
    create: async ({ name }) => axios.post(`${ENDPOINT}/`, { name }),
    ...useBasicApi(ENDPOINT, ['get', 'getNext']),
    for: capabilityId => {
      const CAPABILITY_ENDPOINT = `${ENDPOINT}/${capabilityId}`
      const capabilityBasicApi = useBasicApi(CAPABILITY_ENDPOINT, ['get', 'remove'])
      return {
        ENDPOINT: CAPABILITY_ENDPOINT,
        instances: instancesApi(CAPABILITY_ENDPOINT),
        update: async name => axios.put(`${CAPABILITY_ENDPOINT}/`, { name }),
        ...capabilityBasicApi,
        deleteItem: async item => {
          item.loadingDelete = true
          const { confirmAction } = await import('@/composables/useConfirmationDialogs')
          const confirmed = await confirmAction({
            text: `Do you want to delete capability ${item.name || ''}?`,
          })
          if (!confirmed) {
            item.loadingDelete = false
            return
          }
          try {
            await capabilityBasicApi.remove()
          } finally {
            item.loadingDelete = false
          }
        },
      }
    },
  })
}
