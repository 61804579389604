import { ref, watch } from 'vue'
import useDebounceFunction from '@/composables/useDebounceFunction'

/**
 * Composable to sync props value with inner value copy
 *
 * @param {import('vue').Ref<any>} value The outer props value ref to be synced
 * @param {Function} emit The emit function
 * @param {{ converter: any => any,
 *    prevent: any => boolean,
 *    updateEventName: string,
 *    updateAlways: boolean,
 *    delay: number
 * }} options
 *   The options
 *
 * @returns {{
 *   innerValue: import('vue').Ref<any>
 * }} Ref of inner value copy that will be synced
 *   with outer value
 */
export default function useInnerValue(value, emit, options) {
  let {
    converter = v => v,
    prevent = () => false,
    updateEventName = 'update:value',
    updateAlways = false,
    delay = 0,
  } = options ?? {}

  const innerValue = ref(converter(value.value))
  const update = () => {
    const converted = converter(innerValue.value)
    if (innerValue.value !== converted) innerValue.value = converted
    if (converted === value.value && !updateAlways) return
    emit(updateEventName, converted)
  }
  const postprocess = delay < 1 ? update : useDebounceFunction(update, delay)
  emit(updateEventName, converter(innerValue.value))
  watch(value, v => (innerValue.value = converter(v)))
  watch(innerValue, v => {
    if (prevent(v)) return
    postprocess()
  })

  return {
    innerValue,
  }
}
