import useBasicApi from '@/api/useBasicApi'
import { ServerEvent } from '@/enums'

export default function statusApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/status`

  return {
    ENDPOINT,
    CREATE_EVENT: ServerEvent.TASK_STATUS_CREATED,
    DELETE_EVENT: ServerEvent.TASK_STATUS_DELETED,
    UPDATE_EVENT: ServerEvent.TASK_STATUS_UPDATED,
    ...useBasicApi(ENDPOINT, ['get', 'getNext']),
    for: taskStatusId => {
      const TASK_STATUS_ENDPOINT = `${ENDPOINT}/${taskStatusId}`
      return {
        ENDPOINT: TASK_STATUS_ENDPOINT,
        ...useBasicApi(TASK_STATUS_ENDPOINT, ['get', 'remove']),
      }
    },
  }
}
