import idEndpointGetter from '@/api/idEndpointGetter'
import sseApi from '@/api/modules/events/sse'
import hooksApi from '@/api/modules/events/hooks'

export default function eventsApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/events`
  return idEndpointGetter({
    ENDPOINT,
    sse: sseApi(ENDPOINT),
    hooks: hooksApi(ENDPOINT),
  })
}
