<template>
  <component
    :is="component"
    :key="component"
    ref="link"
    :[propLink]="to"
    class="app-text-link group"
    :target="newTab ? '_blank' : undefined"
    @mouseup="blur()"
  >
    <div class="app-text-link-content">
      <AppIcon
        v-if="prependIcon"
        :icon="prependIcon"
        class="flex-none"
      />
      <slot>
        <h6 class="font-medium">
          {{ text }}
        </h6>
      </slot>
    </div>
    <AppDivider
      v-if="showDivider"
      color="border-link"
      type="bold"
      class="app-text-link-underline scale-x-0 group-hover:scale-x-100 group-focus:scale-x-100"
    />
  </component>
</template>

<script setup>
import { ref, toRef } from 'vue'
import AppDivider from '@/components/App/AppDivider'
import AppIcon from '@/components/App/AppIcon'
import useRouterComponent from '@/composables/useRouterComponent'

const props = defineProps({
  to: [String, Object],
  prependIcon: String,
  text: String,
  routerLink: Boolean,
  newTab: Boolean,
  showDivider: { type: Boolean, default: true },
})
const to = toRef(props, 'to')
const link = ref()
const blur = () => (link.value?.$el || link.value)?.blur?.()
const routerLink = toRef(props, 'routerLink')
const { component, prop: propLink } = useRouterComponent({
  to,
  routerLink,
  linkComponent: 'router-link',
})
</script>

<style lang="sass" scoped>
.app-text-link
  @apply flex flex-col items-center
  @apply pt-0.5 hover:text-link focus:text-link transition duration-300 ease-in-out
  & > .app-text-link-content
    @apply flex flex-row items-center self-start gap-2 py-0.5
  & > .app-text-link-underline
    @apply transition duration-300 ease-in-out
</style>
