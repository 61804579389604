import useBasicApi from '@/api/useBasicApi'
import config from '@/config'
import idEndpointGetter from '@/api/idEndpointGetter'

const axios = config.urls.AXIOS_API

export default function configsApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/configs`
  return idEndpointGetter({
    ENDPOINT,
    ...useBasicApi(ENDPOINT, ['get', 'getNext']),
    create: async (keyword, filename) => axios.post(`${ENDPOINT}/`, { keyword, filename }),
    for: keyword => {
      const CONFIG_ENDPOINT = `${ENDPOINT}/${keyword}`
      return {
        ENDPOINT: CONFIG_ENDPOINT,
        ...useBasicApi(CONFIG_ENDPOINT, ['get', 'remove']),
        update: async filename => axios.put(`${CONFIG_ENDPOINT}/`, { filename }),
      }
    },
  })
}
