<template>
  <svg
    :height="sizePx"
    :width="sizePx"
    :fill="fill"
    :viewBox="viewBox"
    :class="[
      colorClass,
      rotateClass,
      {
        'animate-spin': spin,
        '-scale-x-100': flipH,
        '-scale-y-100': flipV,
      },
    ]"
  >
    <path :d="icon" />
  </svg>
</template>

<script setup>
import { computed, toRefs } from 'vue'

const props = defineProps({
  icon: { type: String },
  spin: { type: Boolean, default: false },
  flipH: { type: Boolean, default: false },
  flipV: { type: Boolean, default: false },
  inactive: { type: Boolean, default: false },
  color: { type: String },
  rotate: { type: [Number, String], validator: v => [-180, -90, -45, 0, 45, 90, 180].includes(+v) },
  size: {
    type: [String, Number],
    validator: v => ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(v) || !isNaN(+v),
    default: 'sm',
  },
  viewBox: { type: String, default: '0 0 24 24' },
})

const { color, rotate, size, inactive } = toRefs(props)

const sizes = { xs: 12, sm: 18, md: 24, lg: 36, xl: 48, xxl: 64 }
const getSize = v => (isNaN(v) ? sizes[v] ?? sizes.sm : v)

const colorClass = computed(() => (color.value ? `text-${color.value}` : undefined))
const rotateClass = computed(() => {
  if (!rotate.value) return undefined
  return +rotate.value > 0 ? `rotate-${rotate.value}` : `-rotate-${Math.abs(+rotate.value)}`
})
const sizePx = computed(() => getSize(size.value))
const fill = computed(() => (inactive.value ? 'rgba(255, 255, 255, 0.3)' : 'currentColor'))
</script>
