import useBasicApi from '@/api/useBasicApi'
import config from '@/config'
import idEndpointGetter from '@/api/idEndpointGetter'

const axios = config.urls.AXIOS_API

export default function streamingApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/streaming`
  const ICE_ENDPOINT = `${ENDPOINT}/ice`

  return idEndpointGetter({
    ENDPOINT,
    ...useBasicApi(ENDPOINT, ['get']),
    ice: {
      ENDPOINT: ICE_ENDPOINT,
      ...useBasicApi(ICE_ENDPOINT, ['get']),
    },
    for: sourceId => {
      const SOURCE_ENDPOINT = `${ENDPOINT}/${encodeURIComponent(sourceId)}`
      return {
        ENDPOINT: SOURCE_ENDPOINT,
        run: (sdp, type) => axios.post(`${SOURCE_ENDPOINT}/`, { sdp, type }),
      }
    },
  })
}
