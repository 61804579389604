import config from '@/config'
import useBasicApi from '@/api/useBasicApi'

const axios = config.urls.AXIOS_API

export default function projectSettingsApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/settings`
  const ENDPOINT_VALUES = `${ENDPOINT}/values`
  const ENDPOINT_VALUES_USER = `${ENDPOINT}/values/user`

  const update = async ({
    properties,
    defs,
    required,
    userOverridableValues,
    overridableSettings,
  }) =>
    axios.patch(`${ENDPOINT}/`, {
      properties,
      defs,
      required,
      user_overridable_values: userOverridableValues,
      overridable_settings: overridableSettings,
    })

  return {
    ...useBasicApi(ENDPOINT, ['get']),
    update,
    values: {
      ...useBasicApi(ENDPOINT_VALUES, ['get']),
      update: async ({ values }) => axios.patch(`${ENDPOINT_VALUES}/`, { values }),
      user: {
        ...useBasicApi(ENDPOINT_VALUES_USER, ['get']),
        update: async ({ values }) => axios.patch(`${ENDPOINT_VALUES_USER}/`, { values }),
      },
    },
  }
}
