import useBasicApi from '@/api/useBasicApi'
import { ServerEvent } from '@/enums'

export default function taskPackagesApi(parentEndpoint = '') {
  const FILE_ENDPOINT = `${parentEndpoint}/file`
  const URL_ENDPOINT = `${parentEndpoint}/url`

  const urlApi = useBasicApi(URL_ENDPOINT, ['get'])
  const fileApi = useBasicApi(FILE_ENDPOINT, ['get'])

  return {
    CREATE_EVENT: ServerEvent.TASK_PACKAGE_CREATED,
    DELETE_EVENT: ServerEvent.TASK_PACKAGE_DELETED,
    UPDATE_EVENT: ServerEvent.TASK_PACKAGE_UPDATED,
    ENDPOINT: parentEndpoint,
    ...useBasicApi(parentEndpoint, ['get', 'remove']),
    file: {
      ENDPOINT: FILE_ENDPOINT,
      ...fileApi,
    },
    url: {
      ENDPOINT: URL_ENDPOINT,
      ...urlApi,
    },
    downloadArtifact: async (loading, pre, post) => {
      pre?.()
      loading.value = true
      try {
        const url = (await urlApi.get()).data.url
        const { downloadByUrl } = await import('@/utils/actions')
        // todo: get real name (cant find in api now)
        downloadByUrl(url, 'artifacts.zip')
      } finally {
        loading.value = false
        post?.()
      }
    },
  }
}
