<template>
  <component
    :is="component"
    :[prop]="to"
    :tabindex="focusable ? 0 : undefined"
    :style="style"
    :class="[bg, outlinedClass]"
  >
    <slot />
  </component>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import useRouterComponent from '@/composables/useRouterComponent'

const props = defineProps({
  height: { type: [Number, String] },
  minHeight: { type: [Number, String] },
  maxHeight: { type: [Number, String] },
  width: { type: [Number, String] },
  minWidth: { type: [Number, String] },
  maxWidth: { type: [Number, String] },
  color: { type: String },
  outlined: { type: Boolean, default: false },
  focusable: { type: Boolean, default: false },
  to: { type: [String, Object], default: undefined },
})

const { height, minHeight, maxHeight, width, maxWidth, minWidth, color, outlined, to } =
  toRefs(props)

const useComputedPx = value =>
  computed(() => (isNaN(+value.value) ? value.value : `${value.value}px`))
const heightCss = useComputedPx(height)
const minHeightCss = useComputedPx(minHeight)
const maxHeightCss = useComputedPx(maxHeight)
const widthCss = useComputedPx(width)
const minWidthCss = useComputedPx(minWidth)
const maxWidthCss = useComputedPx(maxWidth)

const style = computed(() => ({
  height: heightCss.value,
  minHeight: minHeightCss.value,
  maxHeight: maxHeightCss.value,
  width: widthCss.value,
  minWidth: minWidthCss.value,
  maxWidth: maxWidthCss.value,
}))

const bg = computed(() => (color.value ? `bg-${color.value}` : undefined))
const outlinedClass = computed(() => (outlined.value ? 'border border-black' : undefined))

const { component, prop } = useRouterComponent({ to })
</script>
