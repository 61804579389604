import useObserver from './useObserver'
import { ref } from 'vue'

export default function useObserverResize(
  root = undefined,
  options = undefined,
  onObserved = undefined
) {
  const width = ref(0)
  const height = ref(0)

  const updateObserveEntryWidth = entries => {
    entries.forEach(entry => {
      const rect = entry?.target?.getBoundingClientRect()
      width.value = rect?.width ?? 0
      height.value = rect?.height ?? 0
    })
  }

  onObserved ??= updateObserveEntryWidth

  return {
    ...useObserver(ResizeObserver, onObserved, { ...options, root }),
    width,
    height,
  }
}
