<template>
  <div :class="[borderClass, color, { 'w-full': !flex }]" />
</template>

<script setup>
import { computed, toRef } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: 'normal',
    validator(v) {
      return ['normal', 'bold', 'extra-bold'].includes(v)
    },
  },
  color: { type: String, default: 'border-black' },
  flex: { type: Boolean, default: false },
})

const type = toRef(props, 'type')
const classVariants = {
  normal: 'border-b',
  bold: 'border-b-2',
  'extra-bold': 'border-b-2',
}
const borderClass = computed(() => classVariants[type.value] ?? classVariants.normal)
</script>
