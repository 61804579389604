import { getProjectKeyFromRole, user } from './useAuth'
import useDebounce from './useDebounce'
import { ref, watch } from 'vue'
import api from '@/api'

export const projectKey = ref()
export const project = ref()
export const projectLoading = ref(false)

useDebounce(
  projectKey,
  150,
  () => {
    const v = projectKey.value
    console.log({ GET_PROJECT: v })
    if (!v) {
      project.value = undefined
      projectLoading.value = false
      return
    }
    api.projects
      .for(v)
      .get()
      .then(({ data }) => {
        console.log({ project: data })
        if (v !== projectKey.value) return
        project.value = data
        projectLoading.value = false
      })
      .catch(() => {
        /* ignore */
      })
  },
  { immediate: true }
)
watch(projectKey, () => {
  projectLoading.value = true
})

const PROJECT_LAST_SELECTED_KEY = 'project.last_selected.key'

export const setProject = key => {
  localStorage.setItem(PROJECT_LAST_SELECTED_KEY, key)
  projectKey.value = key
}

let initPromise = undefined

export const init = async () => {
  if (initPromise) return initPromise
  initPromise = new Promise(resolve => {
    if (projectKey.value === undefined) {
      let pKey = localStorage.getItem(PROJECT_LAST_SELECTED_KEY)
      pKey ??= getProjectKeyFromRole(user.value.roles?.find(it => !!getProjectKeyFromRole(it)))
      console.log({ pKey })
      if (pKey) {
        setProject(pKey)
        return resolve()
      }
      api.projects
        .get()
        .then(({ data: { results } }) => {
          pKey = results?.[0]?.keyword
          setProject(results?.[0]?.keyword)
          resolve()
        })
        .catch(() => {
          /* ignore */
        })
    }
    resolve()
  })
  return initPromise
}
