import useBasicApi from '@/api/useBasicApi'
import config from '@/config'
import { ServerEvent } from '@/enums'
import idEndpointGetter from '@/api/idEndpointGetter'
import HWSCapabilitiesApi from '@/api/modules/projects/hardwareSetups/capabilities'
import devicesApi from '@/api/modules/projects/hardwareSetups/devices'

const axios = config.urls.AXIOS_API

export default function hardwareSetupsApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/hardware_setups`

  return idEndpointGetter({
    ENDPOINT,
    CREATE_EVENT: ServerEvent.HARDWARE_SETUP_CREATED,
    DELETE_EVENT: ServerEvent.HARDWARE_SETUP_DELETED,
    UPDATE_EVENT: ServerEvent.HARDWARE_SETUP_UPDATED,
    // instead of public used publicity because it's JS reserved word
    create: async ({ name, nodeId, publicity }) =>
      axios.post(`${ENDPOINT}/`, {
        name,
        node_id: nodeId,
        public: publicity,
      }),
    ...useBasicApi(ENDPOINT, ['get', 'getNext']),

    for: hardwareSetupId => {
      const HWS_ENDPOINT = `${ENDPOINT}/${hardwareSetupId}`
      return {
        ENDPOINT: HWS_ENDPOINT,
        ...useBasicApi(HWS_ENDPOINT, ['get', 'remove']),
        update: async ({ name, nodeId, publicity }) =>
          axios.patch(`${HWS_ENDPOINT}/`, {
            name,
            node_id: nodeId,
            public: publicity,
          }),
        capabilities: HWSCapabilitiesApi(HWS_ENDPOINT),
        devices: devicesApi(HWS_ENDPOINT),
      }
    },
  })
}
