<template>
  <div
    class="relative"
    @mouseenter.passive="onHoverStart"
    @mousemove.passive="onHoverStart"
    @touchstart.passive="onHoverStart"
    @mouseleave.passive="onHoverFinish"
    @touchend.passive="onHoverFinish"
    @touchcancel.passive="onHoverFinish"
    @click="onHoverFinish"
  >
    <div
      v-if="props.yPosition === 'bottom'"
      ref="activator"
      class="h-full"
      :class="[
        {
          'cursor-pointer': mode === 'click' && !props.disabled,
        },
        activatorClass,
      ]"
      @click="onClickActivator"
    >
      <slot
        name="default"
        :is-open="isReallyOpen"
      />
    </div>
    <div
      ref="targetDropdown"
      :class="[$style.target, $style[xPosition], $style[yPosition]]"
    >
      <Teleport
        :to="teleportToV"
        :disabled="!teleportToV"
      >
        <div
          :style="stylePosition"
          :class="[$style.dropdown, $style[xPosition], $style[yPosition]]"
        >
          <div :class="[$style.dropdownCardContainer, { 'p-4 py-2': isReallyOpenDebounced }]">
            <Transition name="scale">
              <KeepAlive>
                <div
                  v-if="isReallyOpen || dontDestroyOnHide"
                  v-show="isReallyOpen"
                  v-click-outside="onClickOutside"
                  :class="[cardClass, $style.dropdownCard]"
                  :style="{
                    width: contentWidth,
                    minWidth: contentWidth,
                  }"
                  @click="onClickContent"
                >
                  <slot
                    name="content"
                    :width="width"
                    :close="close"
                    :is-open="isReallyOpen"
                  />
                </div>
              </KeepAlive>
            </Transition>
          </div>
        </div>
      </Teleport>
    </div>

    <div
      v-if="props.yPosition === 'top'"
      ref="activator"
      :class="[
        {
          'cursor-pointer': mode === 'click' && !props.disabled,
        },
        activatorClass,
      ]"
      @click="onClickActivator"
    >
      <slot
        name="default"
        :is-open="isReallyOpen"
        :close="close"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onBeforeUnmount, ref, toRef, watch } from 'vue'
import useDebounce from '@/composables/useDebounce'
import useInnerValue from '@/composables/useInnerValue'
import useObserverResize from '@/composables/useObserverResize'
import { useVisibilityToggler } from '@/composables/useVisibilityToggler'

const props = defineProps({
  /** @type {'left' | 'center' | 'right'} */
  xPosition: {
    type: String,
    default: 'right',
    validator: v => ['left', 'center', 'right'].includes(v),
  },
  /** @type {'bottom' | 'top'} */
  yPosition: {
    type: String,
    default: 'bottom',
    validator: v => ['bottom', 'top'].includes(v),
  },
  teleportTo: Object,
  /**
   * @type {'click' | 'hover' | 'passive'} Passive Mode used to control only by isOpen prop without
   *   click or hover
   */
  mode: {
    type: String,
    default: 'click',
    validator: v => ['click', 'hover', 'passive'].includes(v),
  },
  isOpen: { type: Boolean, default: false },
  dontDestroyOnHide: { type: Boolean, default: false },
  permanent: { type: Boolean, default: false },
  cardClass: { type: [String, Array, Object], default: undefined },
  activatorClass: { type: [String, Array, Object], default: undefined },
  closeOnClick: { type: Boolean, default: true },
  useActivatorSize: { type: Boolean, default: false },
  useClickOutside: { type: Boolean, default: false },
  sizeRef: { type: Object, default: undefined },
  disabled: { type: Boolean, default: false },
  delay: { type: Number, default: 300 },
  absoluteTarget: { type: Boolean, default: true },
})
const emit = defineEmits(['update:is-open'])
const activator = ref()
const useActivatorSize = toRef(props, 'useActivatorSize')
const sizeRef = toRef(props, 'sizeRef')
const sizeRefV = computed(() => sizeRef.value ?? activator.value)
const { width } = useObserverResize(sizeRefV, { isEnabled: useActivatorSize })
const contentWidth = computed(() => (useActivatorSize.value ? `${width.value}px` : undefined))

const disabled = toRef(props, 'disabled')
const isOpen = toRef(props, 'isOpen')
const { innerValue: isOpenV } = useInnerValue(isOpen, emit, {
  updateEventName: 'update:is-open',
})
const isReallyOpen = computed(() => props.permanent || (isOpenV.value && !props.disabled))
const { debounced: isReallyOpenDebounced } = useDebounce(isReallyOpen, 300)
watch(isReallyOpen, v => {
  if (v) isReallyOpenDebounced.value = v
})

const { open, close, toggle } = useVisibilityToggler({
  isVisibleRef: isOpenV,
  isDisabledRef: disabled,
})

const { debounced: isOpenDebounced } = useDebounce(isOpenV, 20)
const onClickOutside = event => {
  if (activator.value === event.target || activator.value.contains(event.target)) return
  isOpenDebounced.value && (props.mode === 'click' || props.useClickOutside) && close()
}
const onClickContent = () => props.closeOnClick && props.mode !== 'hover' && close()
const onClickActivator = () => props.mode === 'click' && toggle()

const targetIsOpen = ref()
const setTargetIsOpen = status => {
  targetIsOpen.value = status
}

const onHoverStart = event => {
  if (props.mode !== 'hover') return
  if (event?.target !== activator.value && !activator.value?.contains(event?.target)) return
  setTargetIsOpen(true)
}
const onHoverFinish = () => {
  if (props.mode !== 'hover') return
  setTargetIsOpen(false)
}
const updateFromTargetIsOpen = () => {
  if (props.mode !== 'hover') return
  if (targetIsOpen.value === isReallyOpen.value) return
  targetIsOpen.value ? open() : close()
}
let activeTimeout = undefined
const setTargetIsOpenTimeout = () => {
  if (activeTimeout) clearTimeout(activeTimeout)
  activeTimeout = setTimeout(updateFromTargetIsOpen, props.delay)
}
watch(targetIsOpen, setTargetIsOpenTimeout)

const position = ref({ top: 0, left: 0 })
const teleportTo = toRef(props, 'teleportTo')
const teleportToV = computed(() => (props.absoluteTarget ? '#absolute-target' : teleportTo.value))
const targetDropdown = ref(null)

const stylePosition = computed(() =>
  teleportToV.value ? { top: `${position.value.top}px`, left: `${position.value.left}px` } : {}
)

watch(
  isReallyOpen,
  async v => {
    await nextTick() // Ensure the DOM is updated
    if (!teleportToV.value) return
    const el =
      typeof teleportToV.value === 'string'
        ? document.querySelector(teleportToV.value)
        : teleportToV.value?.$el || teleportToV.value
    const { top, left } = el?.getBoundingClientRect() || {}
    const { top: topD, left: leftD } = targetDropdown.value?.getBoundingClientRect() || {}
    if (v) position.value = { top: topD - top, left: leftD - left }
  },
  { immediate: true }
)

let originalActivatorPosition = { top: 0, left: 0 }

function closeDropdownOnScroll() {
  const newPosition = activator.value.getBoundingClientRect()
  const { top, left } = originalActivatorPosition

  if (newPosition.top !== top || newPosition.left !== left) {
    close()
  }
}

watch(
  isReallyOpen,
  async v => {
    await nextTick()
    if (v) {
      //Remember current position of activator on card open moment.
      originalActivatorPosition = activator.value.getBoundingClientRect()
      window.addEventListener('scroll', closeDropdownOnScroll, true)
    } else {
      window.removeEventListener('scroll', closeDropdownOnScroll, true)
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  window.removeEventListener('scroll', closeDropdownOnScroll, true)
})

defineExpose({
  open,
  toggle,
  close,
})
</script>

<style module lang="sass">
.target
  @apply absolute
  &.top
    @apply top-0
  &.bottom
    @apply bottom-0
  &.center
    @apply left-1/2 justify-center
  &.right
    @apply left-0 justify-start
  &.left
    @apply right-0 justify-start

.dropdown
  @apply absolute z-[100] flex flex-row overflow-visible
  @apply left-0 top-0

  &.bottom
    & > .dropdownCardContainer
      @apply top-2
  &.top
    & > .dropdownCardContainer
      @apply bottom-2

  &.center
    @apply left-1/2 justify-center
  &.right
    @apply left-0 justify-start
  &.left
    @apply left-0 justify-end

  & > .dropdownCardContainer
    @apply absolute -my-2 -mx-4
    & > .dropdownCard
      @apply rounded border rounded-card border-subsecondary
</style>
