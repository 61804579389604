import { fetchEventSource } from '@microsoft/fetch-event-source'
import { onUnmounted, ref } from 'vue'
import { token } from '@/composables/useAuth'
import config from '@/config'

// todo: probably add auto-convert
export const convert = ev => {
  try {
    return JSON.parse(ev?.data)
  } catch (err) {
    return ev?.data
  }
}

const handlers = ref(new Map())

export const on = (eventName, callback, autoOff = true) => {
  if (!handlers.value.has(eventName)) handlers.value.set(eventName, [])
  handlers.value.get(eventName).push(callback)
  if (autoOff) onUnmounted(() => off(eventName, callback))
}

export const off = (eventName, callback) => {
  if (!handlers.value.has(eventName)) return
  const idx = handlers.value.get(eventName).findIndex(it => it === callback)
  if (idx > -1) handlers.value.get(eventName).splice(idx, 1)
}

const onmessage = ev => {
  // ignore empty / ping events
  if (!ev?.event) return
  console.log({ onmessage: ev })
  if (!handlers.value.has(ev.event)) return
  handlers.value.get(ev.event).forEach(callback => callback(ev))
}

const onopen = ev => {
  console.log({ onopen: ev })
}
const onclose = ev => {
  console.log({ onclose: ev })
}

const controller = ref()
export const close = () => controller.value?.abort?.()

export const connect = async () => {
  close()
  if (!token.value) return
  controller.value = new AbortController()

  const src = await fetchEventSource(`${config.urls.BASE_API_URL}events/sse/`, {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
    openWhenHidden: true,
    signal: controller.value.signal,
    onmessage,
    onopen,
    onclose,
  })
  console.log({ src })
}
